import backendApi from 'store/api/backendApi';

import { Migration, MigrationTable, TableDataParameters, TableField } from './types';
import { GetTableStatsParams, TableStat } from 'http/migration/dto';

export type WithIcon<T> = {
  icon?: JSX.Element;
} & T;

export const migrationApi = backendApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMigrations: builder.query<Migration[], void>({
        query: () => ({
          method: 'get',
          url: '/dataMigration',
        }),
      }),
      getMigrationTablesById: builder.query<MigrationTable[], { migrationId: string }>({
        query: ({ migrationId }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/tables`,
        }),
      }),
      getMigrationTableFieldsById: builder.query<TableField[], { migrationId: string; tableId: string }>({
        query: ({ migrationId, tableId }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/table/${tableId}/fields`,
        }),
      }),
      getMigrationTableStats: builder.query<TableStat[], GetTableStatsParams>({
        query: ({ migrationId, tableId, type, interval, startDate }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/table/${tableId}/stats`,
          params: { type, interval, startDate },
        }),
      }),
      getMigrationTableDataById: builder.query<any[], TableDataParameters>({
        query: ({
          migrationId,
          tableId,
          skip,
          take,
          conditions = [],
          fields = [],
          orderBy = [],
          action = 'retrieve',
        }) => {
          return {
            method: 'post',
            url: `/dataMigration/${migrationId}/table/${tableId}/data?skip=${skip}&take=${take}&action=${action}`,
            body: {
              fields,
              conditions,
              orderBy,
            },
          };
        },
      }),
    };
  },
});

export const {
  usePrefetch,
  useGetMigrationsQuery,
  useLazyGetMigrationsQuery,
  useGetMigrationTablesByIdQuery,
  useLazyGetMigrationTablesByIdQuery,
  useGetMigrationTableDataByIdQuery,
  useLazyGetMigrationTableDataByIdQuery,
  useGetMigrationTableFieldsByIdQuery,
  useLazyGetMigrationTableFieldsByIdQuery,
  useGetMigrationTableStatsQuery,
  useLazyGetMigrationTableStatsQuery,
} = migrationApi;
