import { shallowEqual, useSelector } from 'react-redux';
import { useActionCreator } from 'hooks';
import { RootState } from 'store/types';
import { DashboardStateHook } from './types';
import {
  setSelectedMigrationId,
  setSelectedTable,
  setQualityTimelineInterval,
  setQualityTimelineStartDate,
  setQualityTimelineAdditionalTables,
} from './actions';

export const useDashboard = (): DashboardStateHook => {
  const dataDashboardState = useSelector((state: RootState) => state.dashboard, shallowEqual);

  return {
    ...dataDashboardState,
    methods: {
      setSelectedMigrationId: useActionCreator(setSelectedMigrationId),
      setSelectedTable: useActionCreator(setSelectedTable),
      setQualityTimelineInterval: useActionCreator(setQualityTimelineInterval),
      setQualityTimelineStartDate: useActionCreator(setQualityTimelineStartDate),
      setQualityTimelineAdditionalTables: useActionCreator(setQualityTimelineAdditionalTables),
    },
  };
};
