import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';

import { Container } from '../ui';

export default function SalesTeamSpace() {
  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}
      >
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Sales Team Space
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Search Record"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '300px' }}
            />

            <Button variant="outlined" startIcon={<FilterListIcon />} disabled>
              Filters
            </Button>

            <Button variant="outlined" startIcon={<ShareIcon />}>
              Share
            </Button>

            <Button variant="contained">
              <SettingsIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" mb={1}>
          Welcome to your Graph Space!
        </Typography>
        <Typography
          variant="labelRegular12"
          color="neutral.n400"
          align="center"
          sx={{
            display: 'block',
            mb: 3,
            width: '260px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          You’ve successfully created you Graph Space. You can start by adding data sources.
        </Typography>

        <img
          src="https://media.discordapp.net/attachments/1257774543884259331/1293697149330002045/image.png?ex=670850dc&is=6706ff5c&hm=ff052556a3b5009219b6f410f241575f76add9abcb6c9876d7a55ecfc4f409bb&=&format=webp&quality=lossless"
          alt="Placeholder"
          style={{ width: 'auto', height: '500px' }}
        />

        <Button sx={{ mt: 2 }} variant="contained">
          + Add Data Sources
        </Button>
      </Box>
    </Container>
  );
}
