import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';

interface GraphSpaceDetailsProps {
  onFormComplete: (name: string, description: string, isValid: boolean) => void;
}

const GraphSpaceDetails: React.FC<GraphSpaceDetailsProps> = ({ onFormComplete }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (value.length > 40) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  useEffect(() => {
    const isFormValid =
      formData.name.length > 0 && formData.description.length > 0 && !errors.name && !errors.description;
    onFormComplete(formData.name, formData.description, isFormValid);
  }, [formData, errors, onFormComplete]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ my: 5 }}>
        Graph Space Details
      </Typography>

      <Box
        sx={{
          p: 3,
          width: '100%',
          maxWidth: '550px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        component="form"
      >
        <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start' }}>
          Name
        </Typography>
        <Box sx={{ position: 'relative', width: '100%', mb: 3 }}>
          <TextField
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={errors.name}
            helperText={errors.name ? 'Maximum 40 characters allowed' : ''}
            inputProps={{ maxLength: 40 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">{`${formData.name.length}/40`}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start' }}>
          Description
        </Typography>
        <Box sx={{ position: 'relative', width: '100%' }}>
          <TextField
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={errors.description}
            helperText={errors.description ? 'Maximum 40 characters allowed' : ''}
            inputProps={{ maxLength: 40 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">{`${formData.description.length}/40`}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GraphSpaceDetails;
