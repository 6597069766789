import { useState } from 'react';

export interface GraphSpace {
  name: string;
  description: string;
  template: string;
  type: string;
}

export const useGraphSpaceSetUp = () => {
  const [graphSpace, setGraphSpace] = useState<GraphSpace>({
    name: '',
    description: '',
    template: '',
    type: '',
  });

  const steps = [
    {
      label: 'Graph Space Details',
      validate: () => graphSpace.name !== '' && graphSpace.description !== '',
    },
    {
      label: 'Set-up Graph Space',
      validate: () =>
        graphSpace.name !== '' && graphSpace.description !== '' && graphSpace.template !== '' && graphSpace.type !== '',
      button: 'Set-up Graph Space',
    },
  ];

  const handleFormComplete = (name: string, description: string) => {
    setGraphSpace((prev) => ({ ...prev, name, description }));
  };

  const handleTemplateSelect = (template: string, type: string) => {
    setGraphSpace((prev) => ({ ...prev, template, type }));
  };

  return {
    graphSpace,
    handleFormComplete,
    handleTemplateSelect,
    steps,
  };
};
