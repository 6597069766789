import { backendApi } from 'store/api';

import { Integration } from './types';

export const integrationApi = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    postAuthCallback: builder.mutation<any, string>({
      query: (payload) => ({
        method: 'post',
        url: `/integration/callback/${payload}`,
      }),
    }),
    getIntegrations: builder.query<Integration[], void>({
      query: () => ({
        method: 'get',
        url: '/integration',
      }),
    }),
    getIntegration: builder.query<Integration, string>({
      query: (id) => ({
        method: 'get',
        url: `/integration/${id}`,
      }),
    }),
    authorizeIntegration: builder.mutation<string, string>({
      query: (id) => ({
        method: 'get',
        url: `/integration/${id}/authorize`,
      }),
      transformResponse(baseQueryReturnValue: any) {
        return baseQueryReturnValue.data as string;
      },
    }),
    uninstallIntegration: builder.mutation<any, string>({
      query: (id) => ({
        method: 'delete',
        url: `/integration/${id}/uninstall`,
      }),
    }),
  }),
});

export const {
  useGetIntegrationQuery,
  useGetIntegrationsQuery,
  usePostAuthCallbackMutation,
  useUninstallIntegrationMutation,
  useAuthorizeIntegrationMutation,
} = integrationApi;
