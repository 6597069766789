import { Box, Grid, Typography } from '@mui/material';
import { Container } from '../ui';
import CustomStepper from 'components/CustomStepper/CustomStepper';
import GraphSpaceDetails from './components/GraphSpaceDetails';
import GraphSpaceTemplate from './components/GraphSpaceTemplate';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import { useGraphSpaceSetUp } from './useGraphSpaceSetUp';

export default function GraphSpaceSetUp() {
  const { graphSpace, handleFormComplete, handleTemplateSelect, steps } = useGraphSpaceSetUp();

  const stepContent = [
    <GraphSpaceDetails key="graph-space-details" onFormComplete={handleFormComplete} />,
    <GraphSpaceTemplate key="graph-space-template" onTemplateSelect={handleTemplateSelect} />,
  ];

  return (
    <Container
      sx={{
        display: 'grid',
        gridTemplateRows: '84px auto',
        gap: 2,
      }}
    >
      <Grid container sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}>
        <Grid item xs={12}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Graph Space Set-Up
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ overflow: 'auto' }}>
        <CustomStepper steps={steps} stepContent={stepContent} finalRoute={PRIVATE_ABS_ROUTE_PATHS.salesTeamSpace} />
      </Box>
    </Container>
  );
}
