import { createAction } from '@reduxjs/toolkit';
import { TableStatInterval } from 'http/migration/dto';

const SET_SELECTED_MIGRATION_ID = 'dashboard/SET_SELECTED_MIGRATION_ID';
const SET_SELECTED_TABLE = 'dashboard/SET_SELECTED_TABLE';
const SET_QUALITY_TIMELINE_INTERVAL = 'dashboard/SET_QUALITY_TIMELINE_INTERVAL';
const SET_QUALITY_TIMELINE_START_DATE = 'dashboard/SET_QUALITY_TIMELINE_START_DATE';
const SET_QUALITY_TIMELINE_ADDITIONAL_TABLES = 'dashboard/SET_QUALITY_TIMELINE_ADDITIONAL_TABLES';

export const setSelectedMigrationId = createAction<string>(SET_SELECTED_MIGRATION_ID);
export const setSelectedTable = createAction<string>(SET_SELECTED_TABLE);
export const setQualityTimelineInterval = createAction<TableStatInterval>(SET_QUALITY_TIMELINE_START_DATE);
export const setQualityTimelineStartDate = createAction<string>(SET_QUALITY_TIMELINE_INTERVAL);
export const setQualityTimelineAdditionalTables = createAction<string[]>(SET_QUALITY_TIMELINE_ADDITIONAL_TABLES);
