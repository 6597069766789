import { useCallback, useEffect, useState } from 'react';
import {
  useGetMigrationsQuery,
  useLazyGetMigrationTablesByIdQuery,
  useLazyGetMigrationTableFieldsByIdQuery,
} from 'store/migration/api';
import { Migration, MigrationTable } from 'store/migration/types';

export const useDefaultMigration = () => {
  const migrationsQuery = useGetMigrationsQuery();
  const [defaultTable, setDefaultTable] = useState<MigrationTable | null>(null);
  const [defaultMigration, setDefaultMigration] = useState<Migration | null>(null);
  const [getMigrationTablesById, migrationTablesQuery] = useLazyGetMigrationTablesByIdQuery();
  const [getMigrationTableFieldsById, migrationTableFieldsQuery] = useLazyGetMigrationTableFieldsByIdQuery();

  const handleMigrationData = useCallback(
    (migrationId: string) => {
      const migration = migrationsQuery.data?.find((m) => {
        return m.dataMigrationId === migrationId;
      });

      if (migration && defaultMigration?.dataMigrationId !== migration.dataMigrationId) {
        setDefaultMigration(migration);
        getMigrationTablesById({ migrationId });
      }
    },
    [defaultMigration?.dataMigrationId, getMigrationTablesById, migrationsQuery.data],
  );

  useEffect(() => {
    if (migrationsQuery.data && migrationsQuery.data?.length > 0) {
      const [firstMigration] = migrationsQuery.data;
      handleMigrationData(firstMigration.dataMigrationId);
    }
  }, [handleMigrationData, migrationsQuery.data, migrationsQuery.isSuccess]);

  useEffect(() => {
    if (migrationTablesQuery.data && migrationTablesQuery.data?.length > 0) {
      let [firstTable] = migrationTablesQuery.data;
      const accountTable = migrationTablesQuery.data.find((table) => {
        if (table.table_name === 'Account') {
          firstTable = table;
        }
      });
      if (accountTable) {
        setDefaultTable(accountTable);
      } else {
        setDefaultTable(firstTable);
      }
    }
  }, [migrationTablesQuery.data, migrationTablesQuery.isSuccess]);

  useEffect(() => {
    if (defaultMigration && defaultTable) {
      getMigrationTableFieldsById(
        { migrationId: defaultMigration?.dataMigrationId, tableId: defaultTable.table_name },
        true,
      );
    }
  }, [defaultMigration, defaultTable, getMigrationTableFieldsById]);

  return {
    migrationsQuery,
    handleMigrationData,
    defaultMigration: {
      ...defaultMigration,
      defaultTable,
      setDefaultTable,
      migrationTablesQuery,
      migrationTableFieldsQuery,
    },
  };
};
