import { useDefaultMigration } from 'pages/Dashboard/hooks/useDefaultMigration';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { Condition } from 'store/migration/types';
import { useDashboard } from 'store/dashboard/hook';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { OrderByOption } from '../DataHealthPanel';

const defaultOrderByOption: OrderByOption = {
  name: 'Last Added',
  icon: AccessTimeIcon,
  value: { fieldName: 'CreatedDate' },
};

const getScoreFilterConditions = (index: number) => {
  if (index == 1) {
    return [{ field: 'confidence_score', operator: '<', value: '70' }];
  }
  if (index == 2) {
    return [
      { field: 'confidence_score', operator: '>=', value: '70' },
      { field: 'confidence_score', operator: '<', value: '90' },
    ];
  }
  if (index == 3) {
    return [{ field: 'confidence_score', operator: '>=', value: '90' }];
  }
  return [];
};

export const useDataHealthHook = () => {
  //State
  const [userFilterIds, setUserFilterIds] = useState<string[]>([]);
  const [scoreFilterIndex, setScoreFilterIndex] = useState<number>(0);
  const [orderByField, setOrderByField] = useState<{ fieldName: string; order: string }>({
    fieldName: defaultOrderByOption.value.fieldName,
    order: 'DESC',
  });

  //Hooks
  const {
    methods: { setSelectedMigrationId, setSelectedTable, setQualityTimelineAdditionalTables },
  } = useDashboard();
  const { defaultMigration } = useDefaultMigration();
  const [getMigrationRecords, migrationRecords] = useLazyGetMigrationTableDataByIdQuery();

  //Functions
  const handleApplyOrderByOption = useCallback((option: OrderByOption) => {
    setOrderByField({ fieldName: option.value.fieldName, order: 'DESC' });
  }, []);

  //Values
  const tableFields = useMemo(
    () => defaultMigration.migrationTableFieldsQuery.data || [],
    [defaultMigration.migrationTableFieldsQuery.data],
  );

  const orderTableOptions = useMemo(() => {
    const copy = defaultMigration.migrationTablesQuery.data?.slice() || [];
    return copy.sort((a, b) => a.table_name.localeCompare(b.table_name));
  }, [defaultMigration.migrationTablesQuery.data]);

  //Effects
  useEffect(() => {
    if (defaultMigration?.dataMigrationId && defaultMigration.dataMigrationId != '') {
      setSelectedMigrationId(defaultMigration.dataMigrationId);
    }
  }, [defaultMigration.dataMigrationId, setSelectedMigrationId]);

  useEffect(() => {
    if (defaultMigration?.defaultTable) {
      setSelectedTable(defaultMigration.defaultTable.table_name);
      setQualityTimelineAdditionalTables([]);
    }
  }, [defaultMigration.defaultTable, setQualityTimelineAdditionalTables, setSelectedTable]);

  useEffect(() => {
    if (defaultMigration?.dataMigrationId && defaultMigration.dataMigrationId != '' && defaultMigration.defaultTable) {
      const conditions = [];

      if (userFilterIds.length > 0) {
        const userFilterCondition: Condition = {
          field: 'OwnerId',
          operator: 'IN',
          value: `(${userFilterIds.map((id) => `'${id}'`).join(',')})`,
        };
        conditions.push(userFilterCondition);
      }

      const scoreFilterConditions = getScoreFilterConditions(scoreFilterIndex);

      if (scoreFilterConditions.length > 0) {
        conditions.push(...scoreFilterConditions);
      }

      getMigrationRecords({
        migrationId: defaultMigration.dataMigrationId,
        tableId: defaultMigration?.defaultTable.table_name || '',
        skip: 0,
        take: 10000,
        conditions: conditions,
        fields: [],
        orderBy: [orderByField],
      });
    }
  }, [
    defaultMigration.dataMigrationId,
    defaultMigration.defaultTable,
    getMigrationRecords,
    orderByField,
    scoreFilterIndex,
    userFilterIds,
  ]);

  return {
    defaultMigration,
    userFilterIds,
    scoreFilterIndex,
    orderByField,
    defaultOrderByOption,
    tableFields,
    orderTableOptions,
    migrationRecords,
    setUserFilterIds,
    setScoreFilterIndex,
    setOrderByField,
    handleApplyOrderByOption,
  };
};
