import React, { useCallback, useEffect, useMemo } from 'react';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { CustomItemTooltipContent, PointPayload } from './components/CustomItemTooltipContent';
import { useDashboard } from 'store/dashboard/hook';
import { DataQualityColors } from 'store/dashboard/types';
import { convertISOStringToLabelInterval, useTableStats } from './hooks/TableStats';
import { Show } from 'components/show';
import { Box, Typography, CircularProgress } from '@mui/material';
import { TableStatInterval } from 'http/migration/dto';

type DataPoint = {
  x: string;
  y: number;
};

type DataSet = {
  id: string;
  data: DataPoint[];
};

export const TimeLinearChart: React.FC = () => {
  const [calculatedData, setCalculatedData] = React.useState<DataSet[]>([]);

  const {
    data: {
      selectedMigrationId,
      selectedTable,
      dataQualityTimeline: { additionalTables, interval, startDate },
    },
  } = useDashboard();

  const selectedTableData = useTableStats({ migrationId: selectedMigrationId, table: selectedTable });
  const t1 = useTableStats({ migrationId: selectedMigrationId, table: additionalTables[0] });
  const t2 = useTableStats({ migrationId: selectedMigrationId, table: additionalTables[1] });
  const t3 = useTableStats({ migrationId: selectedMigrationId, table: additionalTables[2] });
  const t4 = useTableStats({ migrationId: selectedMigrationId, table: additionalTables[3] });
  const t5 = useTableStats({ migrationId: selectedMigrationId, table: additionalTables[4] });

  const formatTimeLabel = useCallback(
    (value: string) => {
      // or startDate is less than 6 months ago
      if (
        interval != TableStatInterval.WEEK ||
        new Date(startDate) >= new Date(new Date().setMonth(new Date().getMonth() - 2))
      ) {
        return value;
      }
      return '';
    },
    [interval, startDate],
  );

  const tableDataMap = useMemo(() => {
    const map = { [selectedTable]: selectedTableData };
    for (let i = 0; i < additionalTables.length; i++) {
      map[additionalTables[i]] = [t1, t2, t3, t4, t5][i];
    }
    return map;
  }, [additionalTables, selectedTable, selectedTableData, t1, t2, t3, t4, t5]);

  const isFetching = useMemo(
    () => Object.values(tableDataMap).some((tableData) => tableData.response.isFetching === true),
    [tableDataMap],
  );

  const isLoading = useMemo(
    () => Object.values(tableDataMap).some((tableData) => tableData.response.isLoading === true),
    [tableDataMap],
  );

  const isSuccess = useMemo(
    () => Object.values(tableDataMap).every((tableData) => tableData.response.isSuccess === true),
    [tableDataMap],
  );

  useEffect(() => {
    if (isFetching === false && isSuccess === true) {
      const formattedData = [];
      for (const [tableName, tableData] of Object.entries(tableDataMap)) {
        const dataPoints: DataPoint[] =
          tableData.response.data?.map((stat) => {
            return {
              x: convertISOStringToLabelInterval(stat.date_start, interval),
              y: stat.score,
              row_count: stat.row_count,
              row_affected_count: stat.row_affected_count,
            };
          }) || [];
        if (dataPoints.length === 0) {
          continue;
        }
        const dataSet: DataSet = {
          id: tableName,
          data: dataPoints,
        };
        formattedData.push(dataSet);
      }
      setCalculatedData(formattedData);
    }
  }, [interval, isFetching, isSuccess]);

  const legendsCalculated: string[] = useMemo(() => {
    const colors = [];
    let index = 0;
    for (const tableData of Object.values(tableDataMap)) {
      if (tableData.response.data && tableData.response.data.length > 0) {
        colors.push(DataQualityColors[index]);
      }
      index++;
    }
    return colors;
  }, [tableDataMap]);

  const getTooltip = useCallback(
    (point: PointTooltipProps) => {
      const tableMapData = tableDataMap[point.point.serieId];
      const pointIndex = parseInt(point.point.id.split('.')[1]);
      let prevPoint = null;
      if (pointIndex !== 0) {
        prevPoint = (tableMapData.response.data || [])[pointIndex - 1];
      }
      return <CustomItemTooltipContent point={point as PointPayload} interval={interval} prevPoint={prevPoint} />;
    },
    [interval, tableDataMap],
  );

  return (
    <>
      <Show when={isFetching === false && calculatedData.length > 0}>
        <ResponsiveLine
          data={calculatedData}
          colors={legendsCalculated}
          margin={{ right: 25, bottom: 22, left: 36, top: 30 }}
          enableGridX={false}
          enableGridY={false}
          useMesh={true}
          yScale={{
            max: 100,
            type: 'linear',
          }}
          axisLeft={{
            tickValues: [0, 25, 50, 75, 100],
            format: (value) => `${value}%`,
          }}
          axisBottom={{ format: formatTimeLabel }}
          tooltip={getTooltip}
        />
      </Show>
      <Show when={isFetching === true || isLoading === true}>
        <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </Show>
      <Show when={isLoading === false && calculatedData.length == 0}>
        <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Typography>No data available to show</Typography>
        </Box>
      </Show>
    </>
  );
};
