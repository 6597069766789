import { Box, MenuItem, Paper, Skeleton, Typography } from '@mui/material';

import { DataQualityTimeline } from '../../DataQualityTimeline/DataQualityTimeline';
import { IntegrationActivityTable } from '../../IntegrationActivity/IntegrationActivityTable';
import { ActiveRulesAndScoreImpact } from '../../ActiveRulesAndImpactScores/ActiveRulesAndScoreImpact';

import DataHealthTabPanel from '../DataHealthPanel';
import DataHealthMetrics from '../DataHealthMetrics';

import { Show } from 'components/show';
import { AutocompleteSelect } from 'components/AutocompleteSelect';
import { useDataHealthHook } from './DataHealthHook';

export function DataHealthTab() {
  const {
    defaultMigration,
    orderTableOptions,
    defaultOrderByOption,
    migrationRecords,
    scoreFilterIndex,
    tableFields,
    userFilterIds,
    setScoreFilterIndex,
    setUserFilterIds,
    handleApplyOrderByOption,
  } = useDataHealthHook();

  return (
    <>
      <Paper elevation={0} sx={{ padding: 2 }}>
        <Box width={'100%'} typography={'body1'}>
          <Box px={1.5} display={'flex'} alignItems={'start'} flexDirection={'row'} justifyContent={'space-between'}>
            <div>
              <Typography variant="h2" fontSize={20}>
                Data Health Map{' '}
                <span
                  style={{
                    fontSize: 12,
                  }}
                >
                  {defaultMigration.dataSource?.name ?? 'loading'}
                </span>
              </Typography>
              <Show when={!!defaultMigration.defaultTable} fallback={<Skeleton height={50} />}>
                <AutocompleteSelect
                  options={orderTableOptions}
                  defaultValue={defaultMigration.migrationTablesQuery.data?.find((t) => {
                    return t.table_name === defaultMigration.defaultTable?.table_name;
                  })}
                  getOptionLabel={(option) => option.table_name}
                  onChange={(_, value) => defaultMigration.setDefaultTable(value)}
                  isOptionEqualToValue={(o, v) => o.table_name === v.table_name}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.table_name} value={option.table_name}>
                      {option.table_name}
                    </MenuItem>
                  )}
                />
              </Show>
            </div>
            <DataHealthMetrics migrationTable={defaultMigration.defaultTable} />
          </Box>
          <Box paddingX={2}>
            <Show when={!!defaultMigration.defaultTable} fallback={<Skeleton height={500} width={'100%'} />}>
              <DataHealthTabPanel
                setOrderByOption={handleApplyOrderByOption}
                setUserFilterIds={setUserFilterIds}
                setScoreFilterIndex={setScoreFilterIndex}
                tableFields={tableFields}
                scoreFilterIndex={scoreFilterIndex}
                elements={migrationRecords.data || []}
                dataLoading={migrationRecords.isLoading || migrationRecords.isFetching}
                defaultOrderByOption={defaultOrderByOption}
                defaultUserIds={userFilterIds}
                dataMigrationId={defaultMigration.dataMigrationId}
              />
            </Show>
          </Box>
        </Box>
      </Paper>
      <br />
      <DataQualityTimeline />
      <br />
      <ActiveRulesAndScoreImpact />
      <br />
      <IntegrationActivityTable />
      <br />
    </>
  );
}
