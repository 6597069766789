import React, { FC, ReactElement, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
  Grid,
  StepConnector,
  styled,
  stepConnectorClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';

interface Step {
  label: string;
  validate: () => boolean;
  button?: string;
}

interface CustomStepperProps {
  steps: Step[];
  stepContent: JSX.Element[];
  finalRoute: string;
}

interface SquareStepIconProps {
  active?: boolean;
  completed?: boolean;
  icon: React.ReactNode;
}

const CustomStepConnector: FC<any> = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderWidth: 5,
  },
}));

const SquareStepIcon: FC<SquareStepIconProps> = (props) => {
  const { active, completed, icon } = props;

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        backgroundColor: completed ? 'primary.main' : active ? 'primary.light' : 'grey.300',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontWeight: 'bold',
      }}
    >
      <Typography variant="body2">{icon}</Typography>
    </Box>
  );
};

const CustomStepper: React.FC<CustomStepperProps> = ({ steps, stepContent, finalRoute }) => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    if (steps[activeStep].validate()) {
      if (activeStep === steps.length - 1) {
        navigate(finalRoute);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
      }}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomStepConnector />}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          padding: '24px 0',
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(props: any) => (
                <SquareStepIcon active={props.active} completed={props.completed} icon={index + 1} />
              )}
            >
              <Typography color="neutral.n400">{step.label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ overflowY: 'auto', padding: 2 }}>{stepContent[activeStep]}</Box>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: 'neutral.white',
          padding: '24px 32px',
        }}
      >
        <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
          Back
        </Button>

        <Grid item>
          <Grid sx={{ display: 'flex', gap: 2 }}>
            <Button variant="text">Cancel</Button>
            <Button variant="contained" onClick={handleNext} disabled={!steps[activeStep].validate()}>
              {isUndefined(steps[activeStep].button) ? 'Next' : steps[activeStep].button}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomStepper;
