import { useState, FC } from 'react';

import { CheckCircle, Warning, Error, Refresh as RefreshIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardMedia, Divider, Grid, Typography } from '@mui/material';

import { Show } from 'components/show';
import { EditableText } from './EditableText';
import { WorkspaceSetting } from './WorkspaceSetting';

interface InvitedMember {
  name: string;
  photo: string;
  isOnline: boolean;
}

interface WorkspaceCardProps {
  icons: string[];
  statusIcon: 'good' | 'warning' | 'bad';
  image: string;
  invitedMembers: InvitedMember[];
  projectName: string;
  createdBy: string;
  description: string;
  lastModified: string;
  modifiedBy: string;
  isArchived: boolean;
  onArchiveToggle: () => void;
}

export const WorkspaceCard: FC<WorkspaceCardProps> = ({
  icons = [],
  statusIcon,
  image,
  invitedMembers = [],
  projectName,
  createdBy,
  description,
  lastModified,
  modifiedBy,
  isArchived,
  onArchiveToggle,
}) => {
  const [currentProjectName, setCurrentProjectName] = useState(projectName);
  const [currentDescription, setCurrentDescription] = useState(description);

  const visibleMembers = invitedMembers.slice(0, 3);
  const remainingMembers = invitedMembers.length - 3;

  return (
    <Card sx={{ px: 4, py: 2, position: 'relative' }} elevation={0}>
      <Box sx={{ pointerEvents: isArchived ? 'none' : 'auto' }}>
        <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="labelRegular12" sx={{ color: 'neutral.n400', marginRight: 1 }}>
                Data Source:
              </Typography>
              {icons.map((src, index) => {
                return (
                  <img
                    src={src}
                    key={index}
                    height={25}
                    style={{
                      marginRight: 3,
                      borderRadius: 100,
                    }}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={'flex-end'} alignItems="center">
              <Typography variant="labelRegular12" sx={{ color: 'neutral.n400', marginRight: 1 }}>
                Status:
              </Typography>
              {statusIcon === 'good' && <CheckCircle color="success" />}
              {statusIcon === 'warning' && <Warning color="warning" />}
              {statusIcon === 'bad' && <Error color="error" />}
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ position: 'relative', height: '172px' }}>
          <CardMedia
            component="img"
            sx={{
              height: '172px',
              display: 'block',
              margin: '0 auto',
              objectFit: 'cover',
            }}
            image={image}
            alt="Project image"
          />
          <Box
            sx={{
              right: 10,
              bottom: 10,
              display: 'flex',
              position: 'absolute',
            }}
          >
            {visibleMembers.map((member, index) => (
              <Avatar
                key={index}
                alt={member.name}
                src={member.photo}
                sx={{
                  marginLeft: index > 0 ? '-10px' : 0,
                  // border: member.isOnline ? '2px solid green' : '2px solid gray',
                }}
              />
            ))}
            {remainingMembers > 0 && (
              <Avatar
                sx={{
                  marginLeft: '-10px',
                  color: 'neutral.main',
                  backgroundColor: 'neutral.white',
                }}
              >
                +{remainingMembers}
              </Avatar>
            )}
          </Box>
        </Box>
        <EditableText
          variant="h4"
          color="neutral.main"
          sx={{ mt: 2 }}
          value={currentProjectName}
          onChange={setCurrentProjectName}
        />
        <Typography variant="p14" sx={{ color: 'neutral.main' }}>
          by {createdBy}
        </Typography>
        <EditableText
          sx={{ mt: 1 }}
          variant="p14"
          color="neutral.n400"
          value={currentDescription}
          onChange={setCurrentDescription}
        />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: 'neutral.n400', mt: 1 }}>
            Notes activity: {lastModified} by {modifiedBy}
          </Typography>
          <WorkspaceSetting onArchiveToggle={onArchiveToggle} />
        </Box>
      </Box>
      <Show when={isArchived}>
        <Box
          sx={{
            top: 0,
            left: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <Button
            variant="outlined"
            onClick={onArchiveToggle}
            startIcon={<RefreshIcon />}
            sx={{ backgroundColor: 'neutral.white', color: 'primary.main', p: 3 }}
          >
            <Typography variant="h4">Restore</Typography>
          </Button>
        </Box>
      </Show>
    </Card>
  );
};
