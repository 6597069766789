import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  setSelectedMigrationId,
  setSelectedTable,
  setQualityTimelineInterval,
  setQualityTimelineStartDate,
  setQualityTimelineAdditionalTables,
} from './actions';
import { DashboardState } from './types';
import _ from 'lodash';
import { TableStatInterval } from 'http/migration/dto';

const lastYearDate = new Date();
lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);
const lastYearDateString = lastYearDate.toISOString().split('T')[0];

export const initialState: DashboardState = {
  data: {
    selectedMigrationId: '',
    selectedTable: '',
    dataQualityTimeline: {
      interval: TableStatInterval.MONTH,
      startDate: lastYearDateString,
      additionalTables: [],
    },
  },
};

const dataAnomalyReducer = createSlice({
  name: 'dashboard',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>): void => {
    builder.addCase(setSelectedMigrationId, (state, { payload }) => {
      state.data.selectedMigrationId = payload;
    });
    builder.addCase(setSelectedTable, (state, { payload }) => {
      state.data.selectedTable = payload;
    });
    builder.addCase(setQualityTimelineInterval, (state, { payload }) => {
      state.data.dataQualityTimeline.interval = payload;
    });
    builder.addCase(setQualityTimelineStartDate, (state, { payload }) => {
      state.data.dataQualityTimeline.startDate = payload;
    });
    builder.addCase(setQualityTimelineAdditionalTables, (state, { payload }) => {
      state.data.dataQualityTimeline.additionalTables = payload;
    });
  },
});

export default dataAnomalyReducer.reducer;
