import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';

import HomeGraphSpace from './HomeGraphSpace';
import EmptyGraphSpace from './EmptyGraphSpace';

import { SortTab } from './components/SortTab';
import { FilterTab } from './components/FilterTab';

import { Container, PrimaryButton } from './ui';
import { Outlet } from 'react-router-dom';

export default function GraphSpace() {
  const [isEmpty, setIsEmpty] = useState(true);
  const [checkedItems, setCheckedItems] = useState({
    active: true,
    archived: false,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePageSwitch = () => {
    setIsEmpty((prev) => !prev);
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent={isEmpty ? 'flex-start' : 'space-between'}
        sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}
      >
        <Grid item xs={isEmpty ? 12 : 6}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Graph Space
            </Typography>
            <Typography sx={{ color: 'neutral.n400' }}>Your collaborative teamspace</Typography>
            <Button variant="contained" color="primary" onClick={handlePageSwitch} sx={{ ml: 2 }}>
              {isEmpty ? 'Workspace' : 'Empty'}
            </Button>
          </Grid>
        </Grid>
        {!isEmpty && (
          <Grid item xs={6}>
            <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FilterTab checkedItems={checkedItems} handleCheckboxChange={handleCheckboxChange} />
              <SortTab />
              <PrimaryButton sx={{ ml: 2 }}>+ Create Graph Space</PrimaryButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      {isEmpty ? <EmptyGraphSpace /> : <HomeGraphSpace checkedItems={checkedItems} />}
      <Outlet />
    </Container>
  );
}
