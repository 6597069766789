import { useCallback, useState } from 'react';
import { Box, Grid, Input, Paper, Skeleton, Typography } from '@mui/material';
import { TimeLinearChart } from './components/TimeLinearChart/TimeLinearChart';
import { DataQualityTable } from './components/DataQualityTable/DataQualityTable';
import { IntervalSelector } from './components/IntervalSelector';
import { LegendRender } from './components/LegendRender';
import { Show } from 'components/show';
import { useDashboard } from 'store/dashboard/hook';
import { AdditionalTableSelector } from './components/AditionalTableSelector';

export const DataQualityTimeline = () => {
  const [selectedNode, setSelectedNode] = useState(false);
  const {
    data: {
      selectedMigrationId,
      selectedTable,
      dataQualityTimeline: { startDate },
    },
    methods: { setQualityTimelineStartDate },
  } = useDashboard();

  const [anchorElTableSelector, setAnchorElTableSelector] = useState<null | HTMLElement>(null);

  const handleClickTableSelector = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTableSelector(event.currentTarget);
  };

  const handleMenuCloseTableSelector = () => {
    setAnchorElTableSelector(null);
  };

  const handleStartDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQualityTimelineStartDate(e.target.value);
    },
    [setQualityTimelineStartDate],
  );

  return (
    <Paper elevation={0} sx={{ padding: 2 }}>
      <Grid container px={1} py={1}>
        <Grid item xs={selectedNode ? 6 : 12}>
          <Box display="flex" alignItems="center" textTransform="capitalize" justifyContent="space-between">
            <Typography variant="h2" fontSize={18}>
              Data Quality Timeline <span style={{ fontSize: 14 }}>object view</span>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography fontSize="12px" color="#9DA1B5">
                Start date:
              </Typography>
              <Typography fontSize="12px" fontWeight={600}>
                <Input type="date" value={startDate} onChangeCapture={handleStartDateChange} />
              </Typography>
            </Box>
            <IntervalSelector />
          </Box>
          <Show when={!!selectedMigrationId && !!selectedTable} fallback={<Skeleton height={300} width={'100%'} />}>
            <Box height={300} onClick={() => setSelectedNode(false)}>
              <TimeLinearChart />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                justifyItems: 'center',
                marginTop: 3,
              }}
            >
              <Box flex={1}>
                <LegendRender />
              </Box>
              <Box width={'fit-context'}>
                <AdditionalTableSelector
                  anchorElTableSelector={anchorElTableSelector}
                  handleClickTableSelector={handleClickTableSelector}
                  handleMenuCloseTableSelector={handleMenuCloseTableSelector}
                />
              </Box>
            </Box>
          </Show>
        </Grid>
        {selectedNode && (
          <Grid item xs={6}>
            <DataQualityTable closeView={() => setSelectedNode(false)} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
