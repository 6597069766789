import { TableStatInterval } from 'http/migration/dto';

export const DataQualityColors: string[] = [
  '#0052D2',
  '#8CB9FF',
  '#00CEDB',
  '#9C27B0',
  '#607D8B',
  '#FFEB3B',
  '#FF9800',
  '#8BC34A',
  '#E91E63',
  '#9E9E9E',
  '#CDDC39',
  '#795548',
];

export interface DashboardState {
  data: {
    selectedMigrationId: string;
    selectedTable: string;
    dataQualityTimeline: {
      interval: TableStatInterval;
      startDate: string;
      additionalTables: string[];
    };
  };
}

export interface DashboardStateHook extends DashboardState {
  methods: {
    setSelectedMigrationId: (args: string) => void;
    setSelectedTable: (args: string) => void;
    setQualityTimelineInterval: (args: TableStatInterval) => void;
    setQualityTimelineStartDate: (args: string) => void;
    setQualityTimelineAdditionalTables: (args: string[]) => void;
  };
}
