import { faker } from '@faker-js/faker';
import { useEffect, useRef, useState } from 'react';

import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { Box, Link, Tooltip, Typography } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { ConfidenceScoreBreakdownData } from '../ConfidenceScoreBreakdownTable';
import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';

export interface DataAnomalyData {
  id: any;
  field: string;
  details: string;
}

const getDataAnomalyDataColumns: (width: number) => GridColumns<DataAnomalyData> = (width) => [
  {
    field: 'field',
    width: width * 0.4,
    renderCell: ({ row }) => {
      return (
        <Tooltip title={row.field}>
          <Link noWrap underline="none">
            {row.field}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'details',
    width: width * 0.8,
    headerName: 'Anomaly Details',
    renderCell: ({ row }) => {
      return (
        <Tooltip title={row.details}>
          <Typography noWrap variant="p14">
            {row.details}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    headerName: '',
    field: 'actions',
    width: width * 0.1,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Tooltip title={`view ${row.field} graph`}>
          <Link target="_blank" underline="none" href="/d/dataGraph">
            <OpenInNewIcon fontSize="small" htmlColor="#1959FF" sx={{ p: 0.2 }} />
          </Link>
        </Tooltip>
      );
    },
  },
];

const pageSize = 6;
const dataRows = Array.from(
  { length: 17 },
  (_, index) =>
    ({
      id: index + 1,
      field: faker.finance.accountName(),
      details: faker.lorem.words({ min: 5, max: 10 }),
    } as DataAnomalyData),
);

type DataAnomalyTableProps = {
  row: ConfidenceScoreBreakdownData;
};

export const DataAnomalyTable = ({ row }: DataAnomalyTableProps) => {
  const ref = useRef<HTMLDivElement>();
  const [columns, setColumns] = useState(getDataAnomalyDataColumns(0));

  const handleResize = () => {
    const width = ref.current?.clientHeight ?? 100;
    setColumns(getDataAnomalyDataColumns(width));
  };

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    return () => ref.current?.removeEventListener('resize', handleResize);
  }, [ref.current]);

  return (
    <Box ref={ref} width={'100%'} height={'100%'}>
      <DataGrid
        rowHeight={40}
        headerHeight={30}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        rows={dataRows}
        columns={columns}
        pageSize={pageSize}
        getRowId={(r) => r.id}
        rowsPerPageOptions={[pageSize]}
        density="comfortable"
        components={{
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
        }}
      />
    </Box>
  );
};
