import { FC, useEffect, useState } from 'react';

import { IconButton, Link, Tooltip, Typography, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ConfidenceScoreBreakdownTable } from './components/ConfidenceScoreBrakdownTable/ConfidenceScoreBreakdownTable';

type DataQualityTableProps = {
  closeView: () => void;
};

interface DataQualityTableItem {
  title: string;
  subtitle: string;
  table: JSX.Element;
}

const defaultTable: DataQualityTableItem = {
  title: 'confidence score breakdown',
  subtitle: 'record view',
  table: <ConfidenceScoreBreakdownTable />,
};

export const DataQualityTable: FC<DataQualityTableProps> = ({ closeView }) => {
  const [tables, setTables] = useState<DataQualityTableItem[]>([defaultTable]);

  const currentTable = tables[tables.length - 1];

  const pushTable = (title: string, subtitle: string, table: JSX.Element) => {
    setTables((prevTables) => [...prevTables, { title, subtitle, table }]);
  };

  const popTable = () => {
    setTables((prevTables) => prevTables.slice(0, -1));
  };

  useEffect(() => {
    setTables((prevTables) => {
      const updatedTables = [...prevTables];
      updatedTables[0] = {
        ...updatedTables[0],
        table: <ConfidenceScoreBreakdownTable pushTable={pushTable} />,
      };
      return updatedTables;
    });
  }, []);

  return (
    <Box height="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between" textTransform="capitalize">
        <Typography variant="h2" fontSize={18} display="flex" alignItems="center">
          {tables.length > 1 && (
            <Tooltip title="back">
              <IconButton onClick={popTable}>
                <ArrowBackIcon fontSize="small" sx={{ mr: 3 }} htmlColor="#898EA1" />
              </IconButton>
            </Tooltip>
          )}
          {currentTable.title}
          <span style={{ fontSize: 14, paddingLeft: 5 }}>{currentTable.subtitle}</span>
        </Typography>
        <Link fontSize={14} underline="none" onClick={closeView} sx={{ cursor: 'pointer' }}>
          close view
        </Link>
      </Box>
      <Box height="95%" width="100%" paddingTop={2}>
        {currentTable.table}
      </Box>
    </Box>
  );
};
